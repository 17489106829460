import {
	ConnectableAccount,
	ConnectableAccountProvider,
	ConnectableAccountType,
} from "@withjuly/fabric";
import { useToast } from "@withjuly/solis";
import posthog from "posthog-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { trpc } from "~/components/Utility/trpc";
import { useApiErrorHandler } from "../api";
import { useCreator } from "../context/creator";
import { openOAuthDialog } from "../oauth";

type LoadingStatus = "window" | "connecting" | "disconnecting" | "done";

export const useConnectAccount = (
	connectable: ConnectableAccount,
	onAccountConnected?: () => void,
	onError?: (error: unknown) => boolean,
	connectionType?: string,
) => {
	const { handleApiError } = useApiErrorHandler();
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("done");
	const { creatorProfile } = useCreator();
	const utils = trpc.useContext();
	const { toast } = useToast();
	const connectAccountMutation = trpc.profile.oauth.connect.useMutation({
		onSuccess: () => {
			utils.user.current.invalidate();
			utils.creator.getManagedCreators.invalidate();
			utils.agency.get.invalidate();
			utils.profile.getAll.invalidate();
		},
	});
	const disconnectAccount = trpc.profile.oauth.disconnect.useMutation({
		onSuccess: () => {
			utils.user.current.invalidate();
			utils.creator.getManagedCreators.invalidate();
			utils.agency.get.invalidate();
			utils.profile.getAll.invalidate();
		},
	});

	const connectAccount = useCallback(
		async (code: string) => {
			setLoadingStatus("connecting");

			connectAccountMutation.mutate(
				{
					provider: connectable.provider,
					code,
					connectionType,
				},
				{
					onSuccess: () => {
						utils.user.current.invalidate();
						utils.creator.getManagedCreators.invalidate();
						onAccountConnected?.();

						posthog.capture("Account Connected", {
							provider: connectable.provider,
						});
					},
					onError: (error) => {
						const handled = onError?.(error) ?? false;
						if (!handled) {
							handleApiError(error);
						}
					},
					onSettled: () => setLoadingStatus("done"),
				},
			);
		},
		[
			connectAccountMutation,
			connectable.provider,
			connectionType,
			handleApiError,
			onAccountConnected,
			onError,
			utils.creator.getManagedCreators,
			utils.user,
		],
	);

	// Handle messages passed from the dialog window
	useEffect(() => {
		const handleMessage = async (e: StorageEvent) => {
			if (loadingStatus === "done") {
				return;
			}

			if (e.key === "JULY_ACCOUNT_CONNECTED") {
				const value = localStorage.getItem("JULY_ACCOUNT_CONNECTED");
				if (!value) {
					return;
				}
				const { provider, code } = JSON.parse(value);

				// Only act on events for this provider
				if (provider === connectable.provider) {
					await connectAccount(code);
					localStorage.removeItem("JULY_ACCOUNT_CONNECTED");
				}
			}
		};

		window.addEventListener("storage", handleMessage);
		return () => {
			window.removeEventListener("storage", handleMessage);
		};
	}, [connectAccount, connectable.provider, loadingStatus]);

	const onConnect = async () => {
		if (connectable.type === ConnectableAccountType.OAUTH) {
			setLoadingStatus("window");
			const dialogWindow = openOAuthDialog(connectable, connectionType);

			// Hide spinner if window gets closed
			// This may seem like a hack but is apparently canonical-ish:
			// https://stackoverflow.com/questions/9388380/capture-the-close-event-of-popup-window-in-javascript
			if (dialogWindow) {
				const timer = setInterval(() => {
					if (dialogWindow.closed) {
						setLoadingStatus((s) => (s === "window" ? "done" : s));
						clearInterval(timer);
					}
				}, 500);
			}
		}
	};

	const onDisconnect = async (
		platformProfileUuid?: string,
		provider?: ConnectableAccountProvider,
	) => {
		const connected = creatorProfile?.connectedAccounts.find((c) => {
			if (platformProfileUuid) {
				return (
					c.platformProfile?.uuid === platformProfileUuid &&
					c.provider === (provider ?? connectable.provider)
				);
			} else {
				return c.provider === (provider ?? connectable.provider);
			}
		});
		if (connected) {
			setLoadingStatus("disconnecting");

			disconnectAccount.mutate(connected.uuid, {
				onSuccess: () => {
					if (provider === ConnectableAccountProvider.GOOGLE) {
						toast({
							title: "Email disconnected",
							description: "Your Gmail account was removed.",
							status: "neutral",
						});
					}
				},
				onError: (error) => handleApiError(error),
				onSettled: () => setLoadingStatus("done"),
			});
		}
	};

	const isConnected = useMemo(() => {
		if (connectionType === "facebook") {
			return (
				creatorProfile?.connectedAccounts.some(
					(c) => c.provider === ConnectableAccountProvider.FACEBOOKPAGE,
				) || false
			);
		}
		return (
			creatorProfile?.connectedAccounts.some(
				(c) => c.provider === connectable.provider,
			) || false
		);
	}, [connectable.provider, connectionType, creatorProfile?.connectedAccounts]);

	const hasIssue = useMemo(() => {
		if (connectionType === "facebook") {
			return (
				creatorProfile?.connectedAccounts.some(
					(account) =>
						account.provider === ConnectableAccountProvider.FACEBOOKPAGE &&
						account.platformProfile?.hasIssue,
				) ?? false
			);
		}
		return (
			creatorProfile?.connectedAccounts.some(
				(account) =>
					account.provider === connectable.provider &&
					account.platformProfile?.hasIssue,
			) ?? false
		);
	}, [connectable.provider, connectionType, creatorProfile?.connectedAccounts]);

	return {
		isLoading: loadingStatus !== "done",
		onConnect,
		onDisconnect,
		isConnected,
		hasIssue,
	};
};
